import React, {useContext, useState} from "react"
import "./Authorization.scss"
import logo_amdg from "../../images/icons/logo-amdg.svg"
import logo_orm from "../../images/icons/logo-orm.svg"
import Input from "../../components/UI/Input/Input"
import RightReserved from "../../components/RightReserved/RightReserved"
import Helmet from "react-helmet"
import {AuthContext} from "../../context/auth/authContext"
import {apiUserLogin} from "../../api/users"
import {PopupContext} from "../../context/popup/popupContext"

const Authorization = () => {
    const popupContextData = useContext(PopupContext)
    const authContextData = useContext(AuthContext)

    const [inputs, setInputs] = useState({
        email: {
            type: 'text',
            name: 'email',
            placeholder: 'Электронная почта',
            value: '',
        },
        password: {
            type: 'password',
            name: 'password',
            placeholder: 'Пароль',
            value: '',
        }
    })

    const fieldChange = (event, name) => {
        const value = event.target.value
        const inputsCopy = {...inputs}
        inputsCopy[name].value = value
        setInputs(inputsCopy)
    }

    const authSend = async (event) => {
        event.preventDefault()

        popupContextData.setPreloader(true)
        const data = await apiUserLogin(inputs)

        if (data.status === 'fail') {
            popupContextData.setPopupNotice({
                state: true,
                title: 'Ошибка',
                text: data.errorText,
            })
        } else {
            localStorage.setItem('token', data.token)
            localStorage.setItem('tokenExp', data.tokenExp)
            localStorage.setItem('accessLevel', data.accessLevel)
            authContextData.setAuth(true)
        }

        popupContextData.setPreloader(false)
    }

    return (
        <>
            <Helmet>
                <title>AMDG</title>
            </Helmet>

            <div className="authorization">
                <div className="authorization__wrap">
                    <div className="authorization__container">
                        <div className="authorization__top-side">
                            <div className="authorization__logo">
                                <div
                                    className="authorization__logo-img"
                                    style={{
                                        backgroundImage: `url(${logo_amdg})`
                                    }}
                                />
                            </div>

                            <div className="authorization__logo authorization__logo_red">
                                <div
                                    className="authorization__logo-img"
                                    style={{
                                        backgroundImage: `url(${logo_orm})`
                                    }}
                                />
                            </div>
                        </div>

                        <form className="authorization__form">
                            <div className="authorization__inputs-container">
                                {Object.keys(inputs).map((inputId, index) => {
                                    const input = inputs[inputId]

                                    return (
                                        <Input
                                            key={index}
                                            type={input.type}
                                            name={input.name}
                                            value={input.value}
                                            placeholder={input.placeholder}
                                            onChange={(event) => fieldChange(event, input.name)}
                                        />
                                    )
                                })}
                            </div>

                            <input type="submit" value="Войти" onClick={authSend}/>
                        </form>
                    </div>

                    <RightReserved
                        classes={[
                            'rights-reserved_margin_top-70px',
                            'rights-reserved_width-453px'
                        ]}
                    />
                </div>
            </div>
        </>
    )
}

export default Authorization